import React, { useState } from "react";
import TrialFormModal from "./TrialFormModal";
import "./SignUp.css";

const SignUp = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);

  const schedule = {
    "Группа начинающих": {
      times: ["ВТ. 20:00 - 21:00", "ЧТ. 20:00 - 21:00", "ВС. 13:00 - 14:00"],
      image: "/images/beginner.jpg",
    },
    "Группа продолжающих": {
      times: ["ВТ. 19:00 - 20:00", "ЧТ. 19:00 - 20:00", "ВС. 13:00 - 14:00"],
      image: "/images/advanced.jpg",
    },
    "Индивидуальные занятия": {
      times: ["время договорное"],
      image: "/images/private.jpg",
    },
  };

  const openScheduleModal = (times) => {
    setModalContent(times);
  };

  const closeModal = () => {
    setModalContent([]);
  };

  return (
    <>
      <h3 className="signup-heading">Выбери свою группу</h3>
      <div className="timetable-section">
        {Object.entries(schedule).map(([group, details]) => (
          <div className="group-card" key={group}>
            <img src={details.image} alt={group} className="group-image" />
            <h3>{group}</h3>
            <p>Описание программы...</p>
            <button
              className="signup-button"
              onClick={() => setIsModalOpen(true)}
            >
              Записаться
            </button>
            <button
              className="schedule-button"
              onClick={() => openScheduleModal(details.times)}
            >
              Расписание
            </button>
          </div>
        ))}
      </div>

      {modalContent.length > 0 && (
        <div className="modal-background" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>Расписание</h3>
            <ul>
              {modalContent.map((time, index) => (
                <li key={index}>{time}</li>
              ))}
            </ul>
            <button className="close-button" onClick={closeModal}>
              Закрыть
            </button>
          </div>
        </div>
      )}

      <TrialFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default SignUp;
