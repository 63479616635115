import React, { useState } from "react";
import styled from "styled-components";

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 50px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 18px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 18px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
`;

const ConsentText = styled.p`
  font-size: 14px;
  color: #777;
  margin-top: 10px;
`;

const TrialFormModal = ({ isOpen, onClose }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [program, setProgram] = useState("Начинающие");
  const [messageTo, setMessageTo] = useState("");

  const handleSubmit = () => {
    const message = `Имя: ${name}\nТелефон: ${phone}\nПрограмма: ${program}\nСообщение: ${messageTo}\nЗапись на пробное занятие`;
    const whatsappUrl = `https://wa.me/+79082056208?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  if (!isOpen) return null;

  return (
    <ModalBackground onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>×</CloseButton>
        <h3>Запись на пробное занятие</h3>
        <Input
          type="text"
          placeholder="Имя"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          type="tel"
          placeholder="Телефон"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <Select value={program} onChange={(e) => setProgram(e.target.value)}>
          <option>Начинающие</option>
          <option>Продолжающие</option>
          <option>Индивидуальные занятия</option>
        </Select>
        <Input
          type="text"
          placeholder="Напишите сообщение"
          value={messageTo}
          onChange={(e) => setMessageTo(e.target.value)}
        />
        <Button onClick={handleSubmit}>Записаться</Button>
        <ConsentText>
          Нажимая кнопку, вы даете согласие на обработку персональных данных
        </ConsentText>
      </ModalContent>
    </ModalBackground>
  );
};

export default TrialFormModal;
